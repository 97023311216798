@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background-color: #502CA8;
}

body, html, h1, h2, p, div, span {
  font-family: 'Nunito', sans-serif;
}

h1 {
  color: #502CA8;
  font-size: 30px;
}

h2 {
  color: #00CBA2;
  font-size: 20px;
}

h3 {
  color: #502CA8;
}

.ant-layout {
  background-color: #502CA8;
  background-image: url('./images/bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  &.ant-layout-header {
    background: #502CA8 !important;
  }
}

.form-wrapper {
  text-align: center;
  background-image: url('./images/form-bg.svg');
  background-position: center;
  background-size: 115%;
  background-repeat: no-repeat;
  padding: 50px;
  .form-section {
    border-radius: 24px;
    padding: 40px;
    background-color: white;
  }
}

.home-layout {
  background-color: white;
}

.card-table {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px 12px 12px 12px;
  padding: 20px;
  min-height: 300px;
}

.card-number {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px 12px 0px 0px;
  padding: 20px;
  text-align: center;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  &.yellow {
    border-color: #F7AB31;
  }
  &.purple {
    border-bottom-color: #502CA8;
  }
  &.cyan {
    border-bottom-color: #00CBA2;
  }
  &.red {
    border-bottom-color: #E7361F;
  }
  &.green {
    border-bottom-color: #1B8048;
  }
  &.orange {
    border-bottom-color: #F06C1A;
  }
}

.card-number .number {
  font-weight: bold;
  font-size: 30px;
}

.card-number .label {
  font-size: 18px;
}

.recharts-responsive-container {
  width: 100%;
  height: 300px !important;
  position: relative;
}

.symbol-item {
  display: flex;
  align-items: center;
  .color {
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  &.somewhat_active {
    .color {
      background-color: #454045
    }
  }
  &.active {
    .color {
      background-color: #FFBD52
    }
  }
  &.low_active {
    .color {
      background-color: #E2DFE2
    }
  }
  &.empty {
    .color {
      background-color: #C17D4F
    }
  }
  &.sedentary {
    .color {
      background-color: #7CDAC2;
    }
  }
  &.highly_active {
    .color {
      background-color: #F6E738;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ant-layout-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-wrapper {
    padding: 0;
  }
}

@primary-color: #00CBA2;@link-color: #1DA57A;@border-radius-base: 2px;@layout-header-background: #502CA8;